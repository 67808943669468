@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/fonts/fonts.css';

html {
	/* font-size: 14px; */
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
	height: 100%;
	overflow-y: hidden;
}

body.beta:after{
	content: "beta";
	position: fixed;
	width: 75px;
	height: 20px;
	background: #f472b6;
	top: 7px;
	right: -20px;
	text-align: center;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
	line-height: 22px;
	transform:rotate(45deg);
	z-index: 45;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

a {
	color: #1450f5;
}

.top-bar {
	min-height: 52px;
}
.prose ol {
	margin-top: 10px;
	margin-bottom: 10px;
	list-style-type: decimal;
	padding-left: 20px;
}
.prose ol li {
	list-style-type: decimal;
	@apply mb-2;
}
.prose ul {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 20px;
}
.prose ul li {
	list-style-type: disc;
}
@media (max-width: 768px) {
	.prose ul {
		@apply -ml-1;
	}
	.prose ol {
		@apply -ml-1;
	}
}
.prose h1 {
	@apply text-2xl font-bold mb-4;
}
.prose h2 {
	@apply text-lg font-bold my-4;
}
.prose h3 {
	@apply text-base font-bold mt-3 mb-2;
}
.prose h4 {
	@apply text-base font-bold mt-3 mb-2;
}
.prose pre {
	@apply my-4 bg-gray-100 p-4 rounded-xl border border-gray-100 overflow-auto;
}
.prose table {
	@apply my-4 min-w-full;
}
.prose thead {
	@apply text-gray-700 uppercase bg-gray-50;
}
.prose th {
	@apply text-left font-semibold p-2;
	background-color: #f3f4f6;
}
.prose tr {
	@apply bg-white;
}
.prose td {
	@apply p-2;
	border: 1px solid #e5e7eb;
}
.prose code {
	@apply whitespace-pre-wrap;
}
.prose p {
	margin-bottom: 10px;
}

#releases-panel {
	white-space: pre-line;
}

.text-cta-blue {
	color: #1450f5;
}
.dashed-underline-blue {
	background-image: linear-gradient(to right, #1450f5 50%, transparent 50%);
	background-position: 0 bottom;
	background-repeat: repeat-x;
	background-size: 6px 3px;
	padding-bottom: 2px
}
.fill-cta-blue {
	filter: brightness(0) saturate(100%) invert(27%) sepia(79%) saturate(4580%) hue-rotate(223deg) brightness(93%) contrast(108%);
}
.fill-green {
	filter: brightness(0) saturate(100%) invert(27%) sepia(79%) saturate(4580%) hue-rotate(223deg) brightness(93%) contrast(108%);
}
.fill-white {
    filter: brightness(0) saturate(100%) invert(100%) brightness(100%) contrast(100%);
}
.fill-red {
    filter: brightness(0) saturate(100%) invert(20%) sepia(100%) saturate(7500%) hue-rotate(356deg) brightness(90%) contrast(85%);
}
.fill-yellow {
    filter: brightness(0) saturate(100%) invert(84%) sepia(8%) saturate(7195%) hue-rotate(359deg) brightness(110%) contrast(105%);
}
.fill-gray-500 {
    filter: brightness(0) saturate(100%) invert(43%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(93%) contrast(88%);
}

.bg-cta-blue {
  background-color: #1450f5;
}
.bg-theme-light-blue {
	background-color: #f3f6fe;
}
.bg-theme-extralight-blue {
	background-color: #f8faff;
}
.bg-theme-gray {
	background-color: #f8f8f8;
}
section {
	margin-top: 24px;
	margin-bottom: 12px;
	font-weight: bold;
}

/* .chat-bubble.ai .content {
	min-width: 630px;
} */

.cmt2 {
	margin-top: 2px;
}

.-cmt2 {
	margin-top: -2px;
}

.action-ready-indicator {
	transition: opacity 300ms ease;
}
.w-68 {
	width: 17rem;
}
.left-68 {
	left: 17rem;
}
.-left-68 {
	left: -17rem;
}
.hide-scroll::-webkit-scrollbar {
	display: none;
}
.h-18 {
	height: 4.5rem;
}
.launcher-mobile {
	min-height: 36px;
}
.capitalize-start:first-letter {
	text-transform: uppercase;
}
.bg-highlight {
	@apply rounded-md bg-green-200;
}
.grid-layout {
	@apply grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1;
}
.grid-cell {
	@apply bg-white p-3 rounded shadow-sm whitespace-pre-wrap;
}
.grid-cell-label {
	@apply text-gray-500 font-bold uppercase text-xs mb-1;
}
@media (min-width: 1280px) {
	.chat-control-panel {
		margin-top: 4.5rem;
	}
}
.chat-control-panel-wrap.responsive-hidden {
	left: -80%;
}
@media (min-width: 1024px) {
	.chat-control-panel-wrap.responsive-hidden {
		left: -50%;
	}
}
@media (min-width: 768px) {
	.chat-control-panel-wrap.responsive-hidden {
		left: -75%;
	}
}
@media (min-width: 1280px) {
	.chat-control-panel-wrap.responsive-hidden {
		left: 0 !important;
	}
}
.beacon {
	position: absolute;
	top:50%;
	left:50%;
	background-color: #1450f5;
	height: 0.65rem;
	width: 0.65rem;
	border-radius: 50%;
	-webkit-animation: grow .4s 1 linear;
	animation: grow .4s 1 linear;
	-webkit-transform: translateX(-50%) translateY(-50%);
  }
.beacon:before {
	position: absolute;
	content: "";
	height: 0.65rem;
	width: 0.65rem;
	left: 0;
	top: 0;
	background-color: transparent;
	border-radius: 50%;
	box-shadow: 0px 0px 2px 2px #1450f5;
	-webkit-animation: active 2s infinite linear;
	animation: active 2s infinite linear;
}

@-webkit-keyframes grow {
	0% {
		-webkit-transform: scale(.1);
	}

	100% {
		-webkit-transform: scale(1);
	}
}

@keyframes grow {
	0% {
		transform: scale(.1);
	}

	100% {
		transform: scale(1);
	}
}

@-webkit-keyframes active{
	0%{
		-webkit-transform: scale(.1);
		opacity: 1;
	}
	70%{
		-webkit-transform: scale(2.5);
		opacity: 0;
	}
	100%{
		opacity: 0;
	}
}

@keyframes active{
	0%{
		transform: scale(.1);
		opacity: 1;
	}
	70%{
		transform: scale(2.5);
		opacity: 0;
	}
	100%{
		opacity: 0;
	}
}